<template>
	<div class="burger-details">
		<div class="burger-details__bread-crumbs-container">
			<router-link
				class="burger-details__breadcrumb-link"
				:to="{ name: 'Burgers' }"
			>
				<Icon
					class="burger-details__link-icon burger-details__link-icon--ranking"
					:icon="['fas', 'list-ol']"
				/>
				<span class="burger-details__breadcrumb-link--ranking">Ranking</span>
			</router-link>
			<Icon
				class="burger-details__breadcrumb-arrow"
				:icon="['fas', 'angle-right']"
			/>
			<router-link
				class="burger-details__breadcrumb-link"
				:to="{
					name: 'JointDetails',
					params: {
						joint: this.slugify(joint.title)
					}
				}"
			>
				<Icon
					class="burger-details__link-icon burger-details__link-icon--joint"
					:icon="['fas', 'store']"
				/>
				<span class="burger-details__breadcrumb-link--joint">{{ joint.title }}</span>
			</router-link>
			<Icon
				:icon="['fas', 'angle-right']"
				class="burger-details__breadcrumb-arrow"
			/>
			<span
				class="burger-details__breadcrumb-text"
			>
				<Icon
					class="burger-details__link-icon burger-details__link-icon--burger"
					:icon="['fas', 'burger']"
				/>
				<span class="burger-details__breadcrumb-link--burger">{{ burger.title }}</span>
			</span>
		</div>
		<div class="burger-details__container">
			<div class="burger-details__header">
				<div class="burger-details__header-subwrapper">
					<h3 class="burger-details__title">{{ burger.title }}</h3>
					<router-link
						class="burger-details__joint"
						:to="{
							name: 'JointDetails',
							params: {
								joint: this.slugify(joint.title)
							}
						}"
					>
						{{ joint.title }}
					</router-link>
				</div>
				<div class="burger-details__score">
					<BurgerScore :score="burger.rating" />
				</div>
			</div>
			<div class="burger-details__tags">
				<div
					v-for="tag in burger.tags"
					:key="tag"
					class="burger-details__tag"
					:class="'burger-details__tag--' + tag"
				>
					<Icon
						class="burger-details__tag-icon"
						:icon="getTagIcon(tag)"
					/>
					{{ getTagText(tag) }}
				</div>
			</div>
			<div
				class="burger-details__content"
				:class="{
					'burger-details__content--image-only': imageExists && !configTextExists
				}"
			>
				<div
					v-for="(section, sectionIndex) in burger.sections"
					:key="sectionIndex"
					:class="[
						'section',
						'section--' + section.type,
						{
							'section--first-text': imageExists && sectionIndex === configTextIndex,
							'section--first-image': imageExists && sectionIndex === firstImageIndex
						}
					]"
				>
					<div v-if="section.type !== 'image' && section.title !== 'Last tested'" class="sec-title">
						{{ section.title }}
						<Bookmark
							v-if="section.title === 'Config'"
							class="burger-details__bookmark"
						>
							<Icon
								:icon="['fac', burger.meat_types[0]]"
							/>
						</Bookmark>
					</div>
					<div v-if="section.type === 'html' && section.lede" class="sec-lede">{{ section.lede }}</div>
					<div v-if="section.type === 'html'" class="sec-body" v-html="section.content"></div>
					<div v-else-if="section.type === 'text' && section.title !== 'Last tested'" class="sec-body">{{ section.content }}</div>
					<div v-else-if="section.type === 'image'" class="sec-body-pic">
						<img :src="section.url" />
					</div>
				</div>
			</div>
			<div
				v-if="lastTestedSection"
				class="burger-details__last-tested-container"
			>
				<span class="burger-details__last-tested-title">Last test: </span>
				<span class="burger-details__last-tested-content">{{ lastTestedSection.content }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import Bookmark from "@/components/Bookmark.vue"
import BurgerScore from "@/components/BurgerScore.vue"
import Slugify from "@/mixins/Slugify.js"

export default {
	components: {
		Bookmark,
		BurgerScore
	},
	mixins: [
		Slugify
	],
	beforeRouteLeave (to, from, next) {
		if (this.clickedBurgersBreadcrumb) {
			if (to.name === "Burgers") {
				return next()
			}
			return this.$router.push({ name: "Burgers" })
		}
		return next()
	},
	data () {
		return {
			clickedBurgersBreadcrumb: false
		}
	},
	computed: {
		lastTestedSection () {
			return this.burger.sections.find(section => section.title === "Last tested")
		},
		joint () {
			return this.$store.getters.JointsAndBurgersBySlug[this.$route.params.joint]
		},
		burger () {
			return this.joint.burgers[this.$route.params.burger]
		},
		imageExists () {
			return this.firstImageIndex !== -1
		},
		configTextExists () {
			return this.configTextIndex !== -1
		},
		firstImageIndex () {
			return this.burger.sections.findIndex(section => section.type === "image")
		},
		configTextIndex () {
			return this.burger.sections.findIndex(section => section.type === "html" && section.title === "Config")
		}
	},
	methods: {
		getTagText (tag) {
			return {
				"special-edition": "Special Edition",
				discontinued: "Discontinued"
			}[tag]
		},
		getTagIcon (tag) {
			return ["fas", {
				"special-edition": "stopwatch",
				discontinued: "heart-crack"
			}[tag]]
		}
	}
}
</script>

<style lang="scss">
	.burger-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		overflow: auto;
		background: #FAF1E4;
		padding-bottom: 64px;

		@media only screen and (max-width: 767px) {
			padding-bottom: 34px;
		}

		.burger-details__bread-crumbs-container {
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			background: #fefefe;
			padding: 0 87px;
			box-sizing: border-box;
			margin-bottom: 54px;
			box-shadow: 0 0 13px #0000001f;

			@media only screen and (max-width: 1200px) {
				padding: 0 30px;
			}

			@media only screen and (max-width: 820px) {
				padding: 0 20px;
			}

			@media only screen and (max-width: 767px) {
				padding: 0 20px;
				margin-bottom: 40px;
			}

			.burger-details__breadcrumb-link,
			.burger-details__breadcrumb-text {
				color: #2c3e50;
				font-size: 20px;
				font-family: 'Nunito Sans', sans-serif;
				font-weight: 500;
				letter-spacing: 0.8px;
				padding: 14px 14px;
			}

			.burger-details__breadcrumb-link {
				&:hover {
					background: #5274971f;
					border-radius: 5px;
				}

				&--burger {
					display:none;
				}

				&--ranking,
				&--joint,
				&--burger {
					@media only screen and (max-width: 767px) {
						display:none;
					}
				}
			}

			.burger-details__breadcrumb-text {
				cursor: default;
				font-family: 'Nunito Sans', sans-serif;
				font-weight: 600;
				letter-spacing: 0.8px;
			}

			.burger-details__breadcrumb-arrow {
				color: #2c3e50;
				padding: 0 10px;
			}

			.burger-details__link-icon {
				color: #2c3e50;
				font-size: 23px;
				margin-right: 10px;

				@media only screen and (max-width: 767px) {
					margin-right: 0px;
				}

				&.fa-store {
					font-size: 21px;
				}

				&.fa-list-ol {
					margin-bottom: -2px;
					font-size: 22px;
				}
			}

			.burger-details__link-icon--joint,
			.burger-details__link-icon--burger {
				margin-top: 1px;
			}
		}

		.burger-details__container {
			// margin: 100px 0;
			background: #FAF1E4;
			cursor: default;
			z-index: 1;
			border-radius: 5px;
			width: 700px;

			@media only screen and (max-width: 767px) {
				margin: 0;
				border-radius: 0;
				width: 100%;
			}

			.burger-details__header {
				align-items: center;
				background: #FAF1E4;
				display: flex;
				margin-bottom: 40px;
				justify-content: space-between;
				padding: 0 10px;

				@media only screen and (max-width: 767px) {
					flex-direction: column;
				}

				.burger-details__header-subwrapper {
					flex-grow: 1;
					display: flex;
					justify-content: flex-end;
					flex-direction: column;

					@media only screen and (max-width: 767px) {
						margin-bottom: 15px;
						align-items: center;
					}

					.burger-details__joint {
						text-align: center;
						display: none;
						color: #2c3e50;
						opacity: 0.7;
						margin-bottom: 8px;

						&:hover {
							text-decoration: underline;
						}

						@media only screen and (max-width: 767px) {
							display: block;
						}
					}

					.burger-details__title {
						color: #2c3e50;
						font-size: 38px;
						letter-spacing: 0.5px;
						margin: 0;

						@media only screen and (max-width: 767px) {
							text-align: center;
						}
					}

					svg {
						margin-left: 20px;
						font-size: 22px;
						margin-bottom: 8px;
					}
				}

				.burger-details__subtitle {
					color: #8b8b8b;
					font-size: 16px;
					margin: 0 0 20px 1px;
				}

				.burger-score__icon {
					height: 36px;
				}

				.glyph-score {
					height: 32px;
				}
			}

			.burger-details__tags {
				@media only screen and (max-width: 767px) {
					margin: 20px 30px;
				}

				.burger-details__tag {
					line-height: 20px;
					border-radius: 4px;
					font-size: 17px;
					font-style: italic;
					font-weight: bold;
					letter-spacing: 2px;
					margin-bottom: 16px;
					padding: 16px;
					text-align: center;
					text-transform: uppercase;
					// box-shadow: 0 0 13px #0000001f;

					&--special-edition {
						background: repeating-linear-gradient(
							45deg,
							#c33037,
							#c33037 10px,
							#F53D46 10px,
							#F53D46 20px
						);
						color: white;

						.burger-details__tag-icon {
							margin-right: 3px;
						}
					}

					&--discontinued {
						background: repeating-linear-gradient(
							45deg,
							#98B8B3,
							#98B8B3 10px,
							#8EABA6 10px,
							#8EABA6 20px
						);
						color: white;
					}
				}
			}

			.burger-details__content {
				background: #FAF1E4;
				display: grid;
				gap: 16px;
				grid-template-columns: 1fr 2fr;
				grid-template-rows: repeat(auto);
				grid-template-areas:
					"firstText firstImage"
				;

				@media only screen and (max-width: 767px) {
					padding: 0 30px;
				}

				@media only screen and (max-width: 660px) {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 20px 30px 0 30px;
					padding: 0;
				}

				&--image-only {
					grid-template-areas:
					"firstImage firstImage"
					;
				}

				.section {
					border-radius: 4px;
					line-height: 20px;
					grid-column: 1 / 3;
					display: flex;
					flex-direction: column;
					// box-shadow: 0 0 13px #0000001f;
					order: 1;

					@media only screen and (max-width: 767px) {
						width: 100%;
					}

					&--first-text {
						grid-area: firstText;
					}

					&--first-image {
						order: 0;
						grid-area: firstImage;
					}

					.sec-body-pic {
						border: 1px solid #0000001c;
					}

					.sec-title {
						background: #ef9443;
						border-radius: 4px 4px 0 0;
						font-size: 15px;
						font-weight: bold;
						letter-spacing: 2px;
						padding: 8px 16px;
						text-transform: uppercase;
						color: white;
						position: relative;

						.burger-details__bookmark {
							position: absolute;
							top: 0;
							right: 10px;

							svg {
								margin-top: -3px;
								font-size: 15px;

								&.fa-cow {
									margin-right: -2px;
								}

								&.fa-pig {
									margin-right: -2px;
								}
							}
						}
					}

					@mixin sec-body {
						background: #ffffff;
						font-size: 15px;
						letter-spacing: -0.2px;
					}

					.sec-lede + .sec-body {
						padding-top: 0;
						border-top: none;
					}

					.sec-lede {
						border-width: 0 1px 0;
						border-style: solid;
						border-color: #0000001c;
					}

					.sec-body {
						border-width: 0 1px 1px;
						border-style: solid;
						border-color: #0000001c;
						border-radius: 0 0 4px 4px;
					}

					.sec-body,
					.sec-lede {
						@include sec-body;
						padding: 20px 26px;
						flex-grow: 1;

						&-pic {
							@include sec-body;
							border-radius: 4px;
							padding: 10px;
							flex-grow: 1;
							align-items: center;
							justify-content: center;
							display: flex;
							flex-direction: column;

							img {
								display: block;
								max-width: 100%;
								object-fit: cover;
								flex-grow: 1;
								width: 100%;
							}
						}

						p:last-of-type {
							margin-bottom: 0;
						}

						ul {
							margin: 0;
							padding-inline-start: 18px;
						}

						li {
							padding-bottom: 5px;
						}

						.extras {
							padding-top: 16px;
						}

						.discontinued {
							opacity: 0.5;
						}

						.emphasized {
							letter-spacing: 0.1px;
							font-style: italic;
							padding-right: 2px;
						}

						.reference,
						.callback {
							font-weight: 600;
							letter-spacing: 0.2px;
							color: #cc8342;

							&:hover {
								text-decoration: underline;
							}
						}
					}

					.sec-lede {
						font-weight: 800;
						padding: 24px 26px 0;
					}
				}
			}

			.burger-details__last-tested-container {
				width: 100%;
				text-align: right;
				padding: 0 7px;
				box-sizing: border-box;
				font-style: italic;
				font-size: 14px;
				opacity: 0.5;

				@media only screen and (max-width: 767px) {
					padding: 0 40px;
					text-align: center;
				}
			}
		}
	}
</style>
