var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"burger-details"},[_c('div',{staticClass:"burger-details__bread-crumbs-container"},[_c('router-link',{staticClass:"burger-details__breadcrumb-link",attrs:{"to":{ name: 'Burgers' }}},[_c('Icon',{staticClass:"burger-details__link-icon burger-details__link-icon--ranking",attrs:{"icon":['fas', 'list-ol']}}),_c('span',{staticClass:"burger-details__breadcrumb-link--ranking"},[_vm._v("Ranking")])],1),_c('Icon',{staticClass:"burger-details__breadcrumb-arrow",attrs:{"icon":['fas', 'angle-right']}}),_c('router-link',{staticClass:"burger-details__breadcrumb-link",attrs:{"to":{
				name: 'JointDetails',
				params: {
					joint: this.slugify(_vm.joint.title)
				}
			}}},[_c('Icon',{staticClass:"burger-details__link-icon burger-details__link-icon--joint",attrs:{"icon":['fas', 'store']}}),_c('span',{staticClass:"burger-details__breadcrumb-link--joint"},[_vm._v(_vm._s(_vm.joint.title))])],1),_c('Icon',{staticClass:"burger-details__breadcrumb-arrow",attrs:{"icon":['fas', 'angle-right']}}),_c('span',{staticClass:"burger-details__breadcrumb-text"},[_c('Icon',{staticClass:"burger-details__link-icon burger-details__link-icon--burger",attrs:{"icon":['fas', 'burger']}}),_c('span',{staticClass:"burger-details__breadcrumb-link--burger"},[_vm._v(_vm._s(_vm.burger.title))])],1)],1),_c('div',{staticClass:"burger-details__container"},[_c('div',{staticClass:"burger-details__header"},[_c('div',{staticClass:"burger-details__header-subwrapper"},[_c('h3',{staticClass:"burger-details__title"},[_vm._v(_vm._s(_vm.burger.title))]),_c('router-link',{staticClass:"burger-details__joint",attrs:{"to":{
						name: 'JointDetails',
						params: {
							joint: this.slugify(_vm.joint.title)
						}
					}}},[_vm._v(" "+_vm._s(_vm.joint.title)+" ")])],1),_c('div',{staticClass:"burger-details__score"},[_c('BurgerScore',{attrs:{"score":_vm.burger.rating}})],1)]),_c('div',{staticClass:"burger-details__tags"},_vm._l((_vm.burger.tags),function(tag){return _c('div',{key:tag,staticClass:"burger-details__tag",class:'burger-details__tag--' + tag},[_c('Icon',{staticClass:"burger-details__tag-icon",attrs:{"icon":_vm.getTagIcon(tag)}}),_vm._v(" "+_vm._s(_vm.getTagText(tag))+" ")],1)}),0),_c('div',{staticClass:"burger-details__content",class:{
				'burger-details__content--image-only': _vm.imageExists && !_vm.configTextExists
			}},_vm._l((_vm.burger.sections),function(section,sectionIndex){return _c('div',{key:sectionIndex,class:[
					'section',
					'section--' + section.type,
					{
						'section--first-text': _vm.imageExists && sectionIndex === _vm.configTextIndex,
						'section--first-image': _vm.imageExists && sectionIndex === _vm.firstImageIndex
					}
				]},[(section.type !== 'image' && section.title !== 'Last tested')?_c('div',{staticClass:"sec-title"},[_vm._v(" "+_vm._s(section.title)+" "),(section.title === 'Config')?_c('Bookmark',{staticClass:"burger-details__bookmark"},[_c('Icon',{attrs:{"icon":['fac', _vm.burger.meat_types[0]]}})],1):_vm._e()],1):_vm._e(),(section.type === 'html' && section.lede)?_c('div',{staticClass:"sec-lede"},[_vm._v(_vm._s(section.lede))]):_vm._e(),(section.type === 'html')?_c('div',{staticClass:"sec-body",domProps:{"innerHTML":_vm._s(section.content)}}):(section.type === 'text' && section.title !== 'Last tested')?_c('div',{staticClass:"sec-body"},[_vm._v(_vm._s(section.content))]):(section.type === 'image')?_c('div',{staticClass:"sec-body-pic"},[_c('img',{attrs:{"src":section.url}})]):_vm._e()])}),0),(_vm.lastTestedSection)?_c('div',{staticClass:"burger-details__last-tested-container"},[_c('span',{staticClass:"burger-details__last-tested-title"},[_vm._v("Last test: ")]),_c('span',{staticClass:"burger-details__last-tested-content"},[_vm._v(_vm._s(_vm.lastTestedSection.content))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }