<template>
	<div
		class="bookmark"
		:style="bookmarkStyle"
	>
		<div
			class="bookmark__slot-container"
			:style="slotContainerStyle"
		>
			<slot class="bookmark__slot"/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		color: { type: String, default: "#c33037" },
		size: { type: [String, Number], default: 3 },
		height: { type: [String, Number], default: 20 },
		width: { type: [String, Number], default: 12 }
	},
	computed: {
		bookmarkStyle () {
			return `height: calc(${this.height}px * ${this.size}); width: calc(${this.width}px * ${this.size}); border-left: calc((${this.width}px / 2) * ${this.size}) solid ${this.color}; border-right: calc((${this.width}px / 2) * ${this.size}) solid ${this.color}; border-bottom: calc((${this.width}px / 2) * ${this.size}) solid transparent;`
		},
		slotContainerStyle () {
			return `left: calc(-${this.width}px * ${this.size} / 2); height: calc(${this.height}px * ${this.size} - ((${this.width}px / 2) * ${this.size})); width: calc(${this.width}px * ${this.size});`
		}
	}
}
</script>

<style lang="scss">
.bookmark{
    position: relative;
    border-bottom: 25px solid transparent;
    box-sizing: border-box;

    .bookmark__slot-container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
			background: #c33037;
    }
}
</style>
